export const openInPopup = (
  url: string,
  options?: { fullscreen?: boolean; width?: number; height?: number },
) => {
  const isMobile = isMobileDevice()

  // On mobile devices, it doesn't make sense to use popups, so we open the url in a new tab
  if (isMobile) {
    const win = window.open(url, '_blank')
    win?.focus()
    return
  }

  const windowMaxHeight = 900
  const width = options?.width ?? 1100
  const height =
    options?.height ?? window.screen.availHeight > windowMaxHeight
      ? windowMaxHeight
      : window.screen.availHeight
  const top = window.outerHeight / 2 + window.screenY - height / 2
  const left = window.outerWidth / 2 + window.screenX - width / 2

  const rules = options?.fullscreen
    ? 'fullscreen'
    : `popup=1, width=${width}, height=${height}, left=${left}, top=${top}, fullscreen=yes`

  const popup = window.open(url, 'popup', rules)

  if (options?.fullscreen && popup) {
    if (popup.outerWidth < screen.availWidth || popup.outerHeight < screen.availHeight) {
      popup.moveTo(0, 0)
      popup.resizeTo(window.screen.availWidth, window.screen.availHeight)
    }
  }

  return popup
}
