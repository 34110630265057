<script lang="ts" setup>
const props = defineProps<{ variant?: 'button' }>()
const emit = defineEmits(['cta-click'])

const { t } = useI18n()
</script>

<template>
  <div class="cart-empty-state">
    <div class="flex flex-col gap-6 text-center my-4">
      <div>
        <img
          class="m-auto aspect-square"
          :class="{ 'w-[72px]': props.variant === 'button', 'w-28': !props.variant }"
          alt=""
          src="/images/cart/empty-cart-desktop.png"
        />
      </div>

      <div class="flex flex-col gap-1">
        <template v-if="props.variant === 'button'">
          <div class="text-gray-400 text-sm">
            {{ t('components.cartEmptyState.title') }}
          </div>

          <RBaseButton
            color="secondary"
            class="uppercase px-6 py-4 text-base font-semibold !border-2 mt-4"
            @click="emit('cta-click')"
          >
            {{ t('components.cartEmptyState.continueShopping') }}
          </RBaseButton>
        </template>

        <template v-else>
          <div class="text-gray-400 text-lg font-semibold">
            {{ t('components.cartEmptyState.title') }}
          </div>
          <div>{{ t('components.cartEmptyState.subtitle') }}</div>
        </template>
      </div>
    </div>
  </div>
</template>
