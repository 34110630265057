<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'

const menuRef = ref()
const route = useRoute()
const isMenuOpen = ref(false)
const isMobile = useIsMobile()
const globalStore = useGlobalStore()

const isLogoVisible = computed(() => !route.meta.noLogo)
const isLinksVisible = computed(() => !route.meta.noLinks)
const isSimpleHeader = computed(() => route.meta.simpleHeader)
const isCartButtonEnabled = computed(() => globalStore.isCheckoutEnabled && route.meta.showCart)

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}

onClickOutside(menuRef, () => {
  if (!isMenuOpen.value) return

  isMenuOpen.value = false
})

const isShareButtonEnabled = computed(() => route.meta.shareable)
</script>

<template>
  <div ref="menuRef">
    <header
      class="app-header h-16 sm:h-20 fixed left-0 top-0 z-30 w-full gap-2 rounded-b-lg flex items-center"
      :class="{ 'drop-shadow-md': !isMenuOpen }"
    >
      <div
        class="flex justify-between items-center py-3 px-4 h-14 sm:px-10 w-full xl:w-[1280px] m-auto"
      >
        <div class="flex gap-4">
          <RAppMenuNavigationButton :is-menu-open="isMenuOpen" @toggle-menu="toggleMenu" />

          <RStoreLogo
            v-if="isLogoVisible"
            class="hidden"
            :class="{
              'md:flex': !isSimpleHeader,
              hidden: isSimpleHeader,
              'fixed left-1/2 -translate-x-1/2 top-5': route.meta.nested,
            }"
          />
        </div>

        <RStoreLogo
          v-if="isLogoVisible"
          class="fixed left-1/2 -translate-x-1/2 md:top-5 min-w-[250px] justify-center"
          :class="{ 'md:hidden': !isSimpleHeader }"
        />

        <ClientOnly>
          <div class="flex items-center gap-4">
            <RHeaderLinks v-if="!isMobile && isLinksVisible" />
            <RMenuShareButton v-if="isShareButtonEnabled" class="max-sm:hidden" />
            <RCartButton v-if="isCartButtonEnabled" />
          </div>
        </ClientOnly>
      </div>
    </header>

    <ClientOnly>
      <RBaseDrawer
        :show="isMenuOpen"
        placement="left"
        :title="$t('components.aboutUs.sectionOne.title')"
        class="!rounded-l-none max-w-[390px] !rounded-br-xl"
        with-backdrop
      >
        <div class="max-h-full overflow-auto pb-12 bg-aqua-haze">
          <RAboutUs no-title />
        </div>
      </RBaseDrawer>
    </ClientOnly>
  </div>
</template>

<style scoped>
.app-header {
  background-color: var(--color-primary);
  color: var(--color-text);
}
</style>
