<script lang="ts" setup>
const share = useShare()
const showPopover = ref(false)
const { t } = useI18n()

const handleTriggerClick = () => {
  if (share.nativeShareIsAvailable.value) share.handleOption()
}
</script>

<template>
  <RPopover
    v-model:show="showPopover"
    class="share-popover"
    :disable="share.nativeShareIsAvailable.value"
  >
    <template #trigger>
      <button
        class="h-9 text-sm"
        :class="[
          'menu-share-button leading-[0] rounded-full font-medium transition-colors px-5 border-solid border-1',
          { active: showPopover },
        ]"
        @click="handleTriggerClick"
      >
        {{ t('components.header.share.title') }}
      </button>
    </template>
    <ul
      v-if="!share.nativeShareIsAvailable.value"
      class="flex justify-center flex-col bg-white rounded-lg overflow-hidden py-2 shadow-2xl"
    >
      <li v-for="(option, index) in share.options" :key="option.id">
        <button
          class="min-w-[185px] w-full py-2.5 px-4 hover:bg-slate-100 transition-colors"
          @click="share.handleOption(option.id)"
        >
          <div class="flex items-center gap-4">
            <div v-if="option.icon" class="rounded-full border-1.5 border-gray-300 p-1">
              <RIcon :name="option.icon" size="20" />
            </div>
            <img v-if="option.logo" :src="option.logo" alt="" />
            <p
              :class="[
                'text-gray-400 whitespace-nowrap',
                { 'font-semibold': share.isActiveFeedback(option.id) },
              ]"
            >
              {{ share.isActiveFeedback(option.id) ? option.feedbackLabel : option.label }}
            </p>
          </div>
        </button>
        <span v-if="index === 0" class="w-full block border border-gray-200" />
      </li>
    </ul>
  </RPopover>
</template>

<style scoped>
.share-popover {
  :deep(.arrow) {
    bottom: -30px;
  }
}
.menu-share-button {
  &:hover,
  &.active {
    background: white;
    color: var(--color-header);
  }
}
</style>
