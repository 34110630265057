<script setup lang="ts">
const currentStore = useCurrentStore()
const localePath = useLocalePath()
</script>

<template>
  <a
    class="flex justify-left items-center gap-2 sm:gap-4 text-base font-medium text-white"
    v-bind="$attrs"
    :href="
      localePath({
        name: 'products',
      })
    "
  >
    <img
      alt=""
      class="h-8 sm:h-10 rounded-full aspect-square border-white"
      :src="optimizedImage(currentStore.profileImageUri || '/images/empty-logo.png', 'thumb')"
    />
    <span>
      {{ currentStore.name }}
    </span>
  </a>
</template>
