<script lang="ts" setup>
const { t } = useI18n()
const share = useShare()
const showDrawer = ref(false)

const handleShowDrawer = (state: boolean) => {
  if (share.nativeShareIsAvailable.value) return share.handleOption()
  showDrawer.value = state
}
</script>

<template>
  <button
    class="floating-share leading-[0] rounded-full font-semibold transition-colors fixed bottom-4 left-4 shadow-top-md w-14 h-14 text-white sm:hidden"
    @click.stop="handleShowDrawer(true)"
  >
    <RIcon size="24" name="mdi:share-variant-outline" />
  </button>
  <RBaseDrawer
    v-if="!share.nativeShareIsAvailable.value"
    v-model:show="showDrawer"
    placement="bottom"
    :show-close="false"
    with-backdrop
    height-class="h-auto"
    padding-class="p-6"
    background-class="bg-white"
  >
    <div class="flex flex-col gap-4">
      <p class="self-center">{{ t('components.floating.share.title') }}</p>

      <span class="w-full block border border-gray-200" />

      <RSocialList :list="share.options.slice(0, 1)" />

      <RSocialList :list="share.options.slice(1)" />

      <button
        class="py-2 px-6 bg-white text-base rounded-full flex-shrink-0 border-1.5 border-black"
        @click="handleShowDrawer(false)"
      >
        {{ t('components.floating.share.actions.cancel') }}
      </button>
    </div>
  </RBaseDrawer>
</template>

<style scoped>
.floating-share {
  background: var(--color-primary);
}
</style>
