<script lang="ts" setup>
import { CartItem } from '~/types/cart'

const isMobile = useIsMobile()
const cartStore = useCartStore()
const currentItem = ref<CartItem>()
const { isOpen, open, close } = useModalState()

const openDeleteModal = (item: CartItem) => {
  currentItem.value = item
  open()
}

const deleteCurrentItem = () => {
  close()

  if (currentItem.value) {
    cartStore.deleteItem(currentItem.value)
  }
}
</script>

<template>
  <div class="cart-items flex flex-col" :class="{ 'gap-2': isMobile }">
    <RCartItem
      v-for="item in cartStore.cart.items"
      :key="item.id"
      :item="item"
      :variant="isMobile ? 'card' : 'list-item'"
      @open-delete-modal="openDeleteModal"
    />

    <RCartDeleteItemModal
      v-if="currentItem"
      :is-open="isOpen"
      :item="currentItem"
      @close="close"
      @confirm="deleteCurrentItem"
    />
  </div>
</template>
