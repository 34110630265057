<script setup lang="ts">
const share = useShare()

const props = defineProps<{
  list: typeof share.options
}>()
</script>
<template>
  <ul class="flex overflow-x-auto">
    <li v-for="option in props.list" :key="option.id">
      <button
        class="w-full py-2.5 px-4 hover:bg-slate-100 transition-colors"
        @click="share.handleOption(option.id)"
      >
        <div class="flex flex-col items-center gap-1">
          <div v-if="option.icon" class="rounded-full border-1.5 border-gray-300 p-1">
            <RIcon :name="option.icon" size="20" class="text-gray-300" />
          </div>
          <img v-if="option.logo" :src="option.logo" alt="" />
          <p
            :class="[
              'text-gray-300 text-xs',
              { 'font-semibold': share.isActiveFeedback(option.id) },
            ]"
          >
            {{ share.isActiveFeedback(option.id) ? option.feedbackLabel : option.label }}
          </p>
        </div>
      </button>
    </li>
  </ul>
</template>
