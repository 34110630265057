<script lang="ts" setup>
import { CartItem } from '~/types/cart'

const emit = defineEmits(['close', 'confirm'])
const props = defineProps<{ isOpen: boolean; item: CartItem }>()

const { t } = useI18n()
const theme = useStoreTheme()
</script>

<template>
  <div class="delete-item-modal">
    <RBaseModal v-bind="props" is-closeable is-closeable-on-esc @close="emit('close')">
      <div class="flex flex-col gap-6">
        <div class="w-24 h-24 self-center relative flex items-center justify-center">
          <RProductImage :picture="props.item.pictures?.[0]" />

          <div
            class="w-10 h-10 rounded-full absolute -bottom-1 -right-1 flex items-center justify-center"
            :style="{ backgroundColor: theme.primary, color: theme.text }"
          >
            <RIcon size="24" name="material-symbols:delete-outline-rounded" />
          </div>
        </div>

        <div class="text-center flex flex-col gap-2">
          <div class="text-base font-semibold text-gray-400">
            {{ t('components.cartDeleteItemModal.title') }}
          </div>
          <div class="text-sm text-gray-300">"{{ props.item.title }}"</div>
        </div>

        <div class="footer flex justify-between gap-2 mt-2">
          <RBaseButton
            color="secondary"
            class="flex-1 py-4 px-6"
            @click.prevent.stop="emit('close')"
          >
            {{ t('components.cartDeleteItemModal.cancel') }}
          </RBaseButton>
          <RBaseButton
            color="primary"
            class="flex-1 py-4 px-6"
            @click.prevent.stop="emit('confirm')"
          >
            {{ t('components.cartDeleteItemModal.confirm') }}
          </RBaseButton>
        </div>
      </div>
    </RBaseModal>
  </div>
</template>
