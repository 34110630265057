<script lang="ts" setup>
const { t } = useI18n()
const itemStore = useItemStore()
const { send } = useWhatsappMessage()

const redirect = () => {
  if (itemStore.currentItem?.id) {
    useItemRedirect(itemStore.currentItem)
    return
  }

  send(t('pages.productIndex.whatsappMessage'))
}
</script>

<template>
  <button
    class="p-[10px] w-14 h-14 z-10 rounded-full bg-whatsapp text-white fixed bottom-4 right-4 shadow-top-md"
    @click="redirect"
  >
    <RIcon name="mdi:whatsapp" size="38" />
  </button>
</template>
