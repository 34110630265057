<script lang="ts" setup>
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const localePath = useLocalePath()

const aboutPath = localePath({ name: 'about' })
const productsPath = localePath({ name: 'index' })
const normalizeRoute = (route: string) => route.replace(/\//gim, '')
const isRouteActive = (path: string) => normalizeRoute(path) === normalizeRoute(route.path)

const handleClick = (path: string) => {
  router.push(path)
}

const links = computed(() => {
  return [
    {
      id: 'products',
      name: t('routes.products'),
      path: productsPath,
      isActive: isRouteActive(productsPath),
    },
    { id: 'about', name: t('routes.about'), path: aboutPath, isActive: isRouteActive(aboutPath) },
  ]
})
</script>

<template>
  <div class="header-links flex gap-4 justify-between items-center">
    <button
      v-for="link in links"
      :key="link.id"
      class="h-9 text-sm button"
      :class="[
        'menu-share-button leading-[0] rounded-full font-medium transition-colors px-5 border-solid border-1',
        { active: link.isActive },
      ]"
      @click="handleClick(link.path)"
    >
      {{ link.name }}
    </button>
  </div>
</template>

<style scoped>
.button {
  &:hover,
  &.active {
    background: white;
    color: var(--color-header);
  }
}
</style>
