<script lang="ts" setup>
import { PromotionType } from '@rediredi/types'
import { CartItem } from '~/types/cart'

const props = defineProps<{ item: CartItem; variant: 'card' | 'list-item' }>()
const emit = defineEmits<{
  (e: 'open-delete-modal', item: CartItem): void
}>()
const { t } = useI18n()
const globalStore = useGlobalStore()
const currency = globalStore.storeCurrency
const isDiscountsEnabled = true

const price = computed(() => {
  const price =
    props.item?.promotion &&
    props.item?.promotion.type !== PromotionType.Label &&
    isDiscountsEnabled
      ? props.item?.promotionPrice
      : props.item?.price

  return price
})
</script>

<template>
  <div class="cart-item">
    <template v-if="props.variant === 'list-item'">
      <div class="flex gap-5">
        <div class="flex aspect-square h-24 items-center justify-center rounded-lg p-1">
          <RProductImage classes="rounded-md" :picture="props.item.pictures?.[0]" />
        </div>
        <div class="flex min-h-full flex-1 flex-col justify-between gap-4">
          <div>
            <div class="text-sm font-medium text-gray-400">{{ props.item.title }}</div>
            <div v-if="props.item.optionMapping?.length" class="mt-2 flex items-center gap-2">
              <span
                v-for="option in props.item.optionMapping"
                :key="option.name"
                class="border bg-white px-2 py-1 text-sm text-gray-400"
              >
                {{ option.value }}
              </span>
            </div>
          </div>
          <div class="flex items-center justify-between gap-4">
            <div class="actions flex justify-end gap-2">
              <RCartDeleteItemButton
                :item="props.item"
                @open="emit('open-delete-modal', props.item)"
              />
              <RCartQuantityInput
                :item="props.item"
                @open-delete-modal="emit('open-delete-modal', props.item)"
              />
            </div>

            <div class="price text-base font-semibold text-gray-400">
              <div v-if="!price?.amount" class="text-right">
                {{ t('globals.noPrice') }}
              </div>
              <div v-else class="flex items-baseline justify-end gap-1">
                <div>
                  {{ formatPriceInParts(price.amount, { currency }).currency }}
                </div>
                <div>
                  {{ formatPriceInParts(price.amount, { currency }).amount }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-4 border-mercury" />
    </template>

    <template v-if="props.variant === 'card'">
      <div class="flex gap-4 rounded-xl border-1 border-gray-100 bg-white p-4">
        <div class="flex aspect-square h-[72px] items-center justify-center rounded-lg p-1">
          <RProductImage classes="rounded-md" :picture="props.item.pictures?.[0]" />
        </div>
        <div class="flex flex-1 flex-col gap-2">
          <div class="text-sm font-medium text-gray-400">{{ props.item.title }}</div>
          <div v-if="props.item.optionMapping?.length" class="flex items-center gap-2">
            <span
              v-for="option in props.item.optionMapping"
              :key="option.name"
              class="border bg-white px-2 py-1 text-sm text-gray-400"
            >
              {{ option.value }}
            </span>
          </div>

          <div class="price">
            <div v-if="!price?.amount" class="text-right text-sm font-semibold text-gray-400">
              {{ t('globals.noPrice') }}
            </div>
            <div
              v-else
              class="flex items-baseline justify-end gap-1 text-base font-semibold text-gray-400"
            >
              <div>
                {{ formatPriceInParts(price.amount, { currency }).currency }}
              </div>
              <div>
                {{ formatPriceInParts(price.amount, { currency }).amount }}
              </div>
            </div>
          </div>

          <div class="actions flex justify-end gap-2">
            <RCartDeleteItemButton
              :item="props.item"
              @open="emit('open-delete-modal', props.item)"
            />
            <RCartQuantityInput
              :item="props.item"
              @open-delete-modal="emit('open-delete-modal', props.item)"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
