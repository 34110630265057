<script lang="ts" setup>
defineEmits(['toggle-menu'])
defineProps<{ isMenuOpen: boolean }>()

const route = useRoute()
const router = useRouter()
const isMobile = useIsMobile()
const globalStore = useGlobalStore()
const localePath = useLocalePath()

const shouldDisplayBackButton = computed(() => {
  return route.meta.nested
})

const handleClick = () => {
  if (globalStore.backButtonBehavior) {
    globalStore.backButtonBehavior()
    return
  }

  router.push(localePath({ name: (route.meta.backTo as string) || 'index' }))
}
</script>

<template>
  <ClientOnly>
    <div v-if="shouldDisplayBackButton || isMobile" class="flex justify-center items-center">
      <button v-if="shouldDisplayBackButton" @click="handleClick">
        <RIcon size="22" name="ic:round-arrow-back" />
      </button>

      <button v-else-if="isMobile" @click="$emit('toggle-menu')">
        <RIcon size="22" :name="isMenuOpen ? 'ic:round-close' : 'ic:round-menu'" />
      </button>
    </div>
  </ClientOnly>
</template>
