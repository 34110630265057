<script setup lang="ts">
const { t } = useI18n()
const cartStore = useCartStore()
</script>

<template>
  <div
    v-if="cartStore.isOpen"
    class="cart-popover opacity-1 w-[584px] rounded-3xl bg-white p-6 text-dusty-gray shadow-2xl"
  >
    <RCartEmptyState v-if="cartStore.isEmpty" />

    <template v-else>
      <div class="mb-4 text-sm font-bold text-gray-400">
        {{ t('components.cartPopover.title') }}

        <span class="font-medium text-gray-300">
          {{ t('components.cartPopover.cartSize', { count: cartStore.size }, cartStore.size) }}
        </span>
      </div>

      <div class="no-scrollbar relative mb-[92px] max-h-[320px] overflow-auto">
        <RCartItems />
      </div>

      <div>
        <ClientOnly>
          <RCartFooter
            class="absolute bottom-0 m-auto w-[calc(100%_-_48px)] rounded-b-2xl border-mercury bg-white px-0 pb-6 pt-4"
            :class="{
              'shadow-top-sm': cartStore.cart.items.length > 2,
            }"
          />
        </ClientOnly>
      </div>
    </template>
  </div>
</template>
