<script lang="ts" setup>
const cartStore = useCartStore()
const isMobile = useIsMobile()

const buttonClasses = computed(() => {
  if (cartStore.isOpen) {
    return ['bg-white', 'cart-button--open']
  }

  return ['bg-transparent']
})
</script>

<template>
  <RPopover v-model:show="cartStore.isOpen" :disable="isMobile">
    <template #trigger>
      <button
        class="cart-button relative h-12 w-12 rounded-full transition-colors"
        :class="buttonClasses"
        @click.stop="cartStore.toggle"
      >
        <RIcon name="ic:outline-shopping-bag"></RIcon>

        <div
          v-if="cartStore.size > 0"
          class="counter absolute bottom-2 right-2 flex h-4 w-4 items-center justify-center rounded-full border-1 border-apple bg-apple text-xs font-semibold text-white transition-colors sm:h-[18px] sm:w-[18px] sm:text-[12px]"
        >
          {{ cartStore.size > 99 ? '99+' : cartStore.size }}
        </div>
      </button>
    </template>

    <RCart />
  </RPopover>
</template>

<style scoped>
.cart-button {
  &--open {
    color: var(--color-primary);

    .counter {
      color: white !important;
    }
  }

  :deep(.r-icon) {
    svg {
      width: 26px;
      height: 26px;

      @media (max-width: 640px) {
        width: 22px;
        height: 22px;
      }
    }
  }
}
</style>
