<script lang="ts" setup>
const isMobile = useIsMobile()
const cartStore = useCartStore()

onMounted(() => {
  const { activate, deactivate } = useBodyScroll()

  watch([() => cartStore.isOpen, () => isMobile.value], ([open, mobile]) => {
    if (open && mobile) {
      deactivate()
      return
    }

    activate()
  })
})
</script>

<template>
  <div class="cart">
    <RCartDrawer v-if="isMobile" />
    <RCartPopover v-else />
  </div>
</template>
