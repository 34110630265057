<script lang="ts" setup>
import { useElementHover } from '@vueuse/core'

import { CartItem } from '~/types/cart'

const props = withDefaults(
  defineProps<{
    item: CartItem
    inputClass?: string
    size?: 'regular' | 'large'
    allowRemove?: boolean
  }>(),
  {
    size: 'regular',
    inputClass: '',
    allowRemove: false,
  },
)

const emit = defineEmits(['open-delete-modal'])

const addButtonRef = ref()
const isMobile = useIsMobile()
const cartStore = useCartStore()
const isHovered = useElementHover(addButtonRef)
const isValid = ref<{ quantity: number; valid: boolean }>({
  quantity: props.item.quantity,
  valid: true,
})
const inputRef = ref()

const isAddDisabled = computed(() => props.item.quantity >= props.item.quantityAvailable)

const toPx = (val: number) => `${val}px`

const handleRemoveItem = () => {
  if (props.item.quantity === 1 && !props.allowRemove) {
    emit('open-delete-modal')
    return
  }

  cartStore.removeItem(props.item)
  inputRef.value.value = props.item.quantity
  setInvalidStatus(props.item.quantity)
}

const handleAddItem = () => {
  cartStore.addItem(props.item)
  inputRef.value.value = props.item.quantity
  setInvalidStatus(props.item.quantity)
}

const tooltipPosition = computed(() => {
  const addButtonEl = addButtonRef.value as HTMLElement
  if (!addButtonEl) return {}

  const rect = addButtonEl.getBoundingClientRect()

  if (isMobile.value) {
    return {
      top: toPx(rect.top - 64),
      right: toPx(0),
    }
  }

  return {
    top: toPx(rect.top - 78),
    left: toPx(rect.left + rect.width / 2 - 150),
  }
})

const setInvalidStatus = (quantity: number) => {
  const isQuantityValid = quantity > 0 && quantity <= props.item.quantityAvailable

  isValid.value = {
    quantity,
    valid: isQuantityValid,
  }
}

const handleInput = (e: Event) => {
  const el = e.target as HTMLInputElement
  const quantity = Number(el.value)

  setInvalidStatus(quantity)

  if (Number.isNaN(quantity) || quantity === 0) {
    cartStore.updateItemQuantity(props.item, 1)
    el.value = ''
    return
  }

  el.value = quantity.toString()

  cartStore.updateItemQuantity(props.item, quantity)
}

const handleInputBlur = () => {
  if (props.item.quantity > props.item.quantityAvailable) {
    cartStore.updateItemQuantity(props.item, props.item.quantityAvailable)
    inputRef.value.value = props.item.quantityAvailable
    setInvalidStatus(props.item.quantityAvailable)
    return
  }

  if (!isValid.value.quantity) {
    cartStore.updateItemQuantity(props.item, 1)
    inputRef.value.value = 1
    setInvalidStatus(1)
  }
}

onMounted(() => {
  setInvalidStatus(props.item.quantity)
  inputRef.value.value = props.item.quantity
})
</script>

<template>
  <div class="cart-quantity-input">
    <div
      class="flex w-[145px] select-none justify-between rounded-full border-1 border-gray-100 text-gray-300"
      :class="[props.inputClass, { 'border-red-300': !isValid?.valid }]"
    >
      <button class="w-[44px] rounded-l-full pl-[6px]" @click="handleRemoveItem">
        <RIcon :size="props.size === 'regular' ? '20' : '32'" name="ic:round-minus" />
      </button>

      <input
        ref="inputRef"
        maxlength="5"
        :class="{ 'text-2xl': props.size === 'large' }"
        class="quantity-input flex h-[42px] w-16 items-center justify-center text-center text-gray-400"
        @input="handleInput"
        @blur="handleInputBlur"
      />

      <button
        ref="addButtonRef"
        class="w-[44px] rounded-r-full pr-[6px] disabled:cursor-not-allowed disabled:text-gray-200"
        :disabled="isAddDisabled"
        @click="handleAddItem"
      >
        <RIcon :size="props.size === 'regular' ? '20' : '32'" name="ic:round-plus" />
      </button>

      <RTransition name="grow-fade">
        <div
          v-if="(isAddDisabled && isHovered) || !isValid?.valid"
          :style="tooltipPosition"
          class="tooltip fixed mx-4 rounded-md bg-gray-400 px-4 py-2 text-white shadow-md sm:mx-0 sm:w-[300px]"
        >
          <div class="arrow"></div>
          {{
            !isValid.quantity
              ? $t('components.cartQuantityInput.minimumStockTooltip')
              : $t('components.cartQuantityInput.maximumStockTooltip', {
                  quantityAvailable: props.item.quantityAvailable,
                })
          }}
        </div>
      </RTransition>
    </div>
  </div>
</template>

<style scoped>
.arrow {
  position: absolute;
  bottom: -14px;
  right: 50%;
  transform: translateX(50%);
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 16px 12px 0 12px;
  border-color: #535353 transparent transparent transparent;

  @media screen and (max-width: 500px) {
    right: 48px;
  }
}
</style>
