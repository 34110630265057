<script lang="ts" setup>
import { DateTime } from 'luxon'

const currentDate = DateTime.now()
const globalStore = useGlobalStore()
const currentStore = useCurrentStore()
const runtimeConfig = useRuntimeConfig()
const { locale, locales, setLocale } = useI18n()
const { acceptedPayments } = usePaymentsOffered()
const { options: acceptedDeliveryMethods } = useDeliveryOptions()

const isLangSelectorVisible = computed(() => {
  const debugMode = localStorage.getItem('rediredi.debug')

  if (debugMode) return true

  return runtimeConfig.public.APP_VERSION === 'local'
})
</script>

<template>
  <footer class="bg-light-gray text-center text-sm text-gray-300">
    <div class="m-auto max-w-[1280px] p-6">
      <div>
        <div class="md:flex md:justify-between md:gap-0">
          <div class="flex-2 flex flex-col gap-4 text-left text-[12px] md:gap-6">
            <div class="text-sm font-bold text-gray-300">{{ $t('store.payments') }}</div>

            <div class="grid grid-cols-1 gap-x-6 gap-y-4">
              <div
                v-for="payment in acceptedPayments"
                :key="payment.name as string"
                class="flex items-center gap-3"
              >
                <RIcon size="24" :name="payment.icon as string" />
                <span class="text-sm font-medium text-gray-300">
                  {{ $t(`globals.paymentMethods.${payment.name}`) }}
                </span>
              </div>
              <div v-if="acceptedPayments.length === 0" class="flex items-center gap-3">
                <RIcon size="24" name="ic:outline-handshake" />
                <span class="text-sm font-medium text-gray-300">
                  {{ $t('globals.paymentMethods.NOT_SPECIFIED') }}
                </span>
              </div>
            </div>
          </div>

          <hr class="my-4 border-t-1 border-gray-100 md:hidden" />

          <div
            class="hidden min-h-[140px] w-[1px] self-stretch bg-gray-100 md:mx-8 md:inline-block lg:mx-6"
          ></div>

          <div class="flex-2 flex flex-col gap-4 text-left text-[12px] md:gap-6">
            <div class="text-sm font-bold text-gray-300">
              {{ $t('components.footer.deliveryMethods') }}
            </div>

            <div class="grid grid-cols-1 gap-x-6 gap-y-4">
              <div
                v-for="deliveryMethods in acceptedDeliveryMethods"
                :key="deliveryMethods.label"
                class="flex items-center gap-3"
              >
                <RIcon size="24" :name="deliveryMethods.icon!" />
                <span class="text-sm font-medium text-gray-300">
                  {{ deliveryMethods.label }}
                </span>
              </div>
            </div>
            <div class="grid grid-cols-1 gap-x-6 gap-y-4">
              <div class="max-w-[260px] text-sm font-medium text-gray-300">
                {{ currentStore.releaseMethod?.description }}
              </div>
            </div>
          </div>

          <hr class="my-4 border-t-1 border-gray-100 md:hidden" />

          <div
            class="hidden min-h-[140px] w-[1px] self-stretch bg-gray-100 md:mx-8 md:inline-block lg:mx-6"
          ></div>

          <div class="flex flex-col items-start justify-start gap-4 md:max-w-[400px] md:gap-6">
            <div class="text-sm font-bold text-gray-300">
              {{ $t('components.footer.contact') }}
            </div>

            <div class="flex flex-col gap-3 text-left text-sm font-medium">
              <div v-if="globalStore.store.email" class="flex items-center justify-start gap-3">
                <RIcon class="inline-block" size="24" name="mdi:email-outline" />
                {{ globalStore.store.email }}
              </div>

              <div v-if="globalStore.phoneNumber" class="flex items-center justify-start gap-3">
                <RIcon class="inline-block" size="24" name="mdi:phone" />
                {{ globalStore.phoneNumber }}
              </div>

              <div v-if="globalStore.mapsAddress" class="flex items-start justify-start gap-3">
                <RIcon class="inline-block" size="24" name="mdi:map-marker-outline" />
                <div class="flex flex-col gap-3">
                  {{ globalStore.storeAddress }}
                  <RStoreMap class="h-[80px]" />
                </div>
              </div>
            </div>
          </div>
          <hr class="my-4 border-t-1 border-gray-100 md:block" />
          <div
            class="hidden min-h-[140px] w-[1px] self-stretch bg-gray-100 md:mx-8 md:inline-block lg:mx-6"
          ></div>
          <div class="flex flex-col gap-3 text-left md:gap-6">
            <div class="text-sm font-bold text-gray-300">
              {{ $t('components.footer.security') }}
            </div>

            <img alt="" class="w-32 sm:w-44 md:m-0" src="/images/footer/site-seguro.png" />
          </div>

          <hr class="my-4 border-t-1 border-gray-100 md:hidden" />
        </div>
      </div>

      <hr class="my-8 hidden border-t-1 border-gray-100 md:block" />

      <div
        class="mt-2 flex flex-col-reverse items-baseline justify-end gap-6 text-gray-300 md:gap-7"
      >
        <div class="w-full text-sm font-medium md:flex md:justify-between">
          <div class="mb-4 md:mb-0 md:flex-1 md:text-left">
            {{
              $t('components.footer.trademark', {
                year: currentDate.year,
                store: currentStore.name,
              })
            }}
          </div>

          <div class="m-auto max-w-[212px] md:max-w-none md:flex-1 md:text-right">
            <i18n-t keypath="components.footer.app.text" scope="global">
              <template #link>
                <a :href="globalStore.siteURL" target="_blank" class="link underline">
                  {{ $t('components.footer.app.link') }}
                </a>
              </template>
            </i18n-t>
          </div>
        </div>
      </div>
      <div
        v-if="isLangSelectorVisible"
        class="mt-4 flex items-center justify-center gap-2 md:justify-start"
      >
        <div class="flex items-center gap-2">
          <RIcon size="24" name="ic:outline-language"></RIcon>
        </div>

        <select
          id="lang-select"
          name="lang"
          @input="(o) => setLocale((o.target as HTMLOptionElement).value)"
        >
          <option
            v-for="l in locales"
            :key="l.code"
            :selected="l.code === locale ? true : undefined"
          >
            {{ l.code }}
          </option>
          <option>de-BG</option>
        </select>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.link {
  color: var(--color-primary);
}
</style>
