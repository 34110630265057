export type ShareOptions = 'copyLink' | 'download' | 'whatsapp' | 'facebook' | 'gmail'

export const useShare = () => {
  const route = useRoute()
  const { t } = useI18n()
  const itemStore = useItemStore()
  const currentStore = useCurrentStore()
  const { event } = useEvent()
  const activeFeedback = ref<ShareOptions | null>(null)

  const options: {
    id: ShareOptions
    label: string
    feedbackLabel?: string
    icon?: string
    logo?: string
  }[] = [
    {
      id: 'copyLink',
      label: t('globals.share.options.copyLink'),
      feedbackLabel: t('globals.share.options.copiedLink'),
      icon: 'material-symbols:link',
    },
    {
      id: 'whatsapp',
      label: t('globals.share.options.whatsapp'),
      logo: '/images/share/whatsapp.svg',
    },
    {
      id: 'facebook',
      label: t('globals.share.options.facebook'),
      logo: '/images/share/facebook.svg',
    },
    {
      id: 'gmail',
      label: t('globals.share.options.gmail'),
      logo: '/images/share/gmail.svg',
    },
  ]

  const nativeShareIsAvailable = ref(typeof navigator.share === 'function')
  const isActiveFeedback = (optionId: ShareOptions) => activeFeedback.value === optionId

  const handleFeedback = (optionId: ShareOptions) => {
    activeFeedback.value = null
    if (!options.find(({ id }) => id === optionId)?.feedbackLabel) return

    activeFeedback.value = optionId
    setTimeout(() => {
      activeFeedback.value = null
    }, 3000)
  }

  const getText = () => {
    if (route.meta.context === 'products.list') {
      return t('components.header.share.products.list', {
        store: currentStore.name,
        storeDetails: currentStore.aboutUs,
      })
    }

    if (route.meta.context === 'products.view') {
      const { amount, currency } = itemStore.currentItem?.baseVariant.price ?? {}
      return t('components.header.share.products.view', {
        price: amount ? formatPrice(amount, { currency: currentStore.currency || currency }) : '-',
      })
    }

    return t('components.header.share.default', {
      store: currentStore.name,
      storeDetails: currentStore.aboutUs,
    })
  }

  const handleOption = async (optionId?: ShareOptions) => {
    const currentLink = window.location.href

    if (optionId) handleFeedback(optionId)

    switch (optionId) {
      case 'copyLink':
        if (
          (await navigator.permissions.query({ name: 'clipboard-write' as PermissionName }))
            .state !== 'granted'
        ) {
          break
        }
        navigator.clipboard.writeText(currentLink)
        break

      case 'whatsapp':
        openInPopup(
          `https://api.whatsapp.com/send?${new URLSearchParams({
            text: getText() + '\n' + currentLink,
          })}`,
        )
        break

      case 'facebook':
        openInPopup(
          `https://www.facebook.com/sharer/sharer.php?${new URLSearchParams({ u: currentLink })}`,
        )
        break

      case 'gmail':
        openInPopup(
          `https://mail.google.com/mail/?${new URLSearchParams({
            view: 'cm',
            fs: '1',
            tf: '1',
            to: '',
            su: getText() || '',
            body: currentLink,
            ui: '2',
            pli: '1',
          })}`,
        )
        break

      default:
        if (nativeShareIsAvailable.value) {
          return await navigator.share({
            url: currentLink,
            text: getText(),
          })
        }
        break
    }

    event('share', {
      content_type: 'link',
      item_id: itemStore.currentItem?.id,
    })
  }

  return { options, handleOption, nativeShareIsAvailable, isActiveFeedback }
}
